import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/layout/Grid.mdx';
import { LayoutResponsiveTabs } from '../../../src/components/content/components/LayoutResponsiveTabs';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const FormattedImage = makeShortcode("FormattedImage");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/layout/lib/Grid.tsx" storybook="https://workday.github.io/canvas-kit/?path=/story/components-layout-grid--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=19740-8633&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "grid-anatomy"
      }}>{`Grid Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6ebe5d0803b4eb232a6ce8b052815c88/2a08f/component-anatomy-grid.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.486486486486484%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Grid with annotation markers.",
              "title": "Image of a Grid with annotation markers.",
              "src": "/static/6ebe5d0803b4eb232a6ce8b052815c88/50383/component-anatomy-grid.png",
              "srcSet": ["/static/6ebe5d0803b4eb232a6ce8b052815c88/1efb2/component-anatomy-grid.png 370w", "/static/6ebe5d0803b4eb232a6ce8b052815c88/50383/component-anatomy-grid.png 740w", "/static/6ebe5d0803b4eb232a6ce8b052815c88/2a08f/component-anatomy-grid.png 1422w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Margins`}</strong>{`: The space at the outer edges of the grid, should always be of equal fixed width.`}</li>
        <li parentName="ol"><strong parentName="li">{`Columns`}</strong>{`: Span the width of the content, use this to align your content correctly.`}</li>
        <li parentName="ol"><strong parentName="li">{`Gutters`}</strong>{`: The space between columns that help separate content.`}</li>
      </ol>
      <h3 {...{
        "id": "12-column-grid"
      }}>{`12 Column Grid`}</h3>
      <p>{`The Canvas grid system uses 12 columns. We chose the 12 column grid system because it is easy to
divide 12 columns into halves, thirds, fourths, and sixths when designing for different screen
sizes. We recommend reducing the number of columns as the screen width reduces as illustrated below.
Check out our `}<a parentName="p" {...{
          "href": "#responsive-layouts"
        }}>{`Responsive Layouts`}</a>{` for recommended sizes.`}</p>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <p>{`The Grid system should be used when designing any layout within your product. If you think of your
UI elements like blocks then these blocks can span across the 12 column grid in any number of ways
as illustrated below. The 12 column grid system gives you a lot of flexibility when arranging UI
elements.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b594222baa1abe5acaa113fa0fa71e55/daed9/component-layout-guidance.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "80%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of blocks overlaying the Grid to indicate how the grid can be divided into different layouts.",
              "title": "Image of blocks overlaying the Grid to indicate how the grid can be divided into different layouts.",
              "src": "/static/b594222baa1abe5acaa113fa0fa71e55/50383/component-layout-guidance.png",
              "srcSet": ["/static/b594222baa1abe5acaa113fa0fa71e55/1efb2/component-layout-guidance.png 370w", "/static/b594222baa1abe5acaa113fa0fa71e55/50383/component-layout-guidance.png 740w", "/static/b594222baa1abe5acaa113fa0fa71e55/daed9/component-layout-guidance.png 1426w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`Not all UI elements within your layout need to align with the column. As long as the parent element
aligns to the assigned number of columns, that’s okay!`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/904919da734a1f13f13cd6821093edf2/daed9/component-alignment.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "65.13513513513513%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of Cards aligning to the Layout Grid.",
              "title": "Image of Cards aligning to the Layout Grid.",
              "src": "/static/904919da734a1f13f13cd6821093edf2/50383/component-alignment.png",
              "srcSet": ["/static/904919da734a1f13f13cd6821093edf2/1efb2/component-alignment.png 370w", "/static/904919da734a1f13f13cd6821093edf2/50383/component-alignment.png 740w", "/static/904919da734a1f13f13cd6821093edf2/daed9/component-alignment.png 1426w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`The cards within the above container do not need to align with the layout columns. You can use the
8pt grid to align the cards.`}</p>
      <h3 {...{
        "id": "responsive-layouts"
      }}>{`Responsive Layouts`}</h3>
      <p>{`Responsive layouts change the appearance of a layout, depending on the screen size and orientation
of the device being used to view it. We recommend using the following grids to cater for these
different screen sizes below.`}</p>
      <LayoutResponsiveTabs mdxType="LayoutResponsiveTabs" />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Breakpoint`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Value`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Columns`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Margin`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Gutter`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Small`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`320px`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`4`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`16px`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`16px`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`768px`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`8`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`40px`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`24px`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Large`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`1024px`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`12`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`40px`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`32px`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Extra Large`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`1440px`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`12`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`80px`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`40px`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "layout-regions"
      }}>{`Layout Regions`}</h3>
      <p>{`Grids should always be used to layout your designs, even when working with different layout regions.
Grids give you greater control over different regions and how you can position UI elements within
your screen layout.`}</p>
      <FormattedImage caption="Main content region spanning across 12 columns" mdxType="FormattedImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/55d8cd2b0188ad6ce761063dcb2f512c/f2f8c/component-main-content.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "61.35135135135135%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of main content region spanning across 12 columns.",
                "title": "Image of main content region spanning across 12 columns.",
                "src": "/static/55d8cd2b0188ad6ce761063dcb2f512c/50383/component-main-content.png",
                "srcSet": ["/static/55d8cd2b0188ad6ce761063dcb2f512c/1efb2/component-main-content.png 370w", "/static/55d8cd2b0188ad6ce761063dcb2f512c/50383/component-main-content.png 740w", "/static/55d8cd2b0188ad6ce761063dcb2f512c/f2f8c/component-main-content.png 1490w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </FormattedImage>
      <FormattedImage caption="Multiple grids used to accommodate layouts with side panel regions" mdxType="FormattedImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/505f8fd942b2eb6950daf79d8e292d42/f2f8c/component-layout-sidebyside.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "61.35135135135135%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of main page content with grid and left and right side panel grids.",
                "title": "Image of main page content with grid and left and right side panel grids.",
                "src": "/static/505f8fd942b2eb6950daf79d8e292d42/50383/component-layout-sidebyside.png",
                "srcSet": ["/static/505f8fd942b2eb6950daf79d8e292d42/1efb2/component-layout-sidebyside.png 370w", "/static/505f8fd942b2eb6950daf79d8e292d42/50383/component-layout-sidebyside.png 740w", "/static/505f8fd942b2eb6950daf79d8e292d42/f2f8c/component-layout-sidebyside.png 1490w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </FormattedImage>
      <FormattedImage caption="Grids used to accommodate layouts with left and right side panels regions that overlays the main page content" mdxType="FormattedImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f563b6fab2fbfaad947883b62652dd71/f2f8c/component-layout-sidebyside-overlay.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "61.35135135135135%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of left and right side panel grids overlaying main page content grid.",
                "title": "Image of left and right side panel grids overlaying main page content grid.",
                "src": "/static/f563b6fab2fbfaad947883b62652dd71/50383/component-layout-sidebyside-overlay.png",
                "srcSet": ["/static/f563b6fab2fbfaad947883b62652dd71/1efb2/component-layout-sidebyside-overlay.png 370w", "/static/f563b6fab2fbfaad947883b62652dd71/50383/component-layout-sidebyside-overlay.png 740w", "/static/f563b6fab2fbfaad947883b62652dd71/f2f8c/component-layout-sidebyside-overlay.png 1490w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </FormattedImage>
      <p>{`In the cases illustrated above, side panels can reduce the width of the main page content or overlay
the page content. Grids of varying columns e.g 4, 8 or 12 can be used to accommodate your layout,
but we recommend reducing the number of columns as the width decreases in order to prevent the
layout becoming cluttered.`}</p>
      <h4 {...{
        "id": "density"
      }}>{`Density`}</h4>
      <p>{`The grid system can help you design for both high and low density layouts. The important thing to
remember when working with density is to increase the margin and gutter space on high density
layouts and decrease the margin and gutter space on low density layouts. This allows the user to
easily scan groups of content.`}</p>
      <SideBySide mdxType="SideBySide">
        <Suggestion guidance="Increase the width of the gutters when laying out components with high density content." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "740px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/af3b801f6cd53158e43500d7a4ca5d25/4719e/density-do.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "20.27027027027027%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image laying out high density cards side by side with increased gutter space between them.",
                  "title": "Image laying out high density cards side by side with increased gutter space between them.",
                  "src": "/static/af3b801f6cd53158e43500d7a4ca5d25/50383/density-do.png",
                  "srcSet": ["/static/af3b801f6cd53158e43500d7a4ca5d25/1efb2/density-do.png 370w", "/static/af3b801f6cd53158e43500d7a4ca5d25/50383/density-do.png 740w", "/static/af3b801f6cd53158e43500d7a4ca5d25/4719e/density-do.png 1330w"],
                  "sizes": "(max-width: 740px) 100vw, 740px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <SideBySide mdxType="SideBySide">
        <Suggestion status="error" guidance="Decrease the width of the gutters when laying out components with high density content." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "740px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/b2538d40eaab9b67482cca0647d453b3/4719e/density-don't.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "21.08108108108108%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image laying out high density cards side by side with decreased gutter space between them.",
                  "title": "Image laying out high density cards side by side with decreased gutter space between them.",
                  "src": "/static/b2538d40eaab9b67482cca0647d453b3/50383/density-don't.png",
                  "srcSet": ["/static/b2538d40eaab9b67482cca0647d453b3/1efb2/density-don't.png 370w", "/static/b2538d40eaab9b67482cca0647d453b3/50383/density-don't.png 740w", "/static/b2538d40eaab9b67482cca0647d453b3/4719e/density-don't.png 1330w"],
                  "sizes": "(max-width: 740px) 100vw, 740px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`To help you make decisions when designing high or low density layouts.`}</li>
        <li parentName="ul">{`Create visual hierarchy and rhythm in your layouts.`}</li>
        <li parentName="ul">{`Create consistent layouts across your product.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      