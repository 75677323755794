
  import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TabbedContent, TabsData} from '../TabbedContent';

const tabsData: TabsData = {
  small: {
    title: 'Small',
    description: '320px - 767px',
    notes: [
      {title: 'Number of columns: 4 \r\n Margins: 16px \r\n Gutter: 16px'},
      {title: 'Used for mobile size screens'},
    ],
    suggest:
      'To accommodate a 3 panel layout on mobile you can add a separate 12 column grid with a margin of 16px and gutter of 16px',
  },
  medium: {
    title: 'Medium',
    description: '768px - 1023px',
    notes: [
      {title: 'Number of columns: 8 \r\n Margins: 40px \r\n Gutter: 24px'},
      {title: 'Used for tablet size screens'},
    ],
  },
  large: {
    title: 'Large',
    description: '1024px - 1439px',
    notes: [
      {title: 'Number of columns: 12 \r\n Margins: 40px \r\n Gutter: 32px'},
      {title: 'Used for desktop and small laptop size screens'},
    ],
  },
  extraLarge: {
    title: 'Extra Large',
    description: '1440px+',
    notes: [
      {title: 'Number of columns: 12 \r\n Margins: 80px \r\n Gutter: 40px'},
      {title: 'Used for large desktop size screens'},
    ],
    suggest:
      'For extra large screens we recommend that you apply a Max-Width of 1440px for the main page content.',
  },
};

export const LayoutResponsiveTabs = () => {
  const {
    allFile: {edges: images},
  } = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^layout-guide/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return <TabbedContent tabsData={tabsData} images={images} isTitleTabNames />;
};

  LayoutResponsiveTabs.__RAW__ = "import React from 'react';\nimport {useStaticQuery, graphql} from 'gatsby';\nimport {TabbedContent, TabsData} from '../TabbedContent';\n\nconst tabsData: TabsData = {\n  small: {\n    title: 'Small',\n    description: '320px - 767px',\n    notes: [\n      {title: 'Number of columns: 4 \\r\\n Margins: 16px \\r\\n Gutter: 16px'},\n      {title: 'Used for mobile size screens'},\n    ],\n    suggest:\n      'To accommodate a 3 panel layout on mobile you can add a separate 12 column grid with a margin of 16px and gutter of 16px',\n  },\n  medium: {\n    title: 'Medium',\n    description: '768px - 1023px',\n    notes: [\n      {title: 'Number of columns: 8 \\r\\n Margins: 40px \\r\\n Gutter: 24px'},\n      {title: 'Used for tablet size screens'},\n    ],\n  },\n  large: {\n    title: 'Large',\n    description: '1024px - 1439px',\n    notes: [\n      {title: 'Number of columns: 12 \\r\\n Margins: 40px \\r\\n Gutter: 32px'},\n      {title: 'Used for desktop and small laptop size screens'},\n    ],\n  },\n  extraLarge: {\n    title: 'Extra Large',\n    description: '1440px+',\n    notes: [\n      {title: 'Number of columns: 12 \\r\\n Margins: 80px \\r\\n Gutter: 40px'},\n      {title: 'Used for large desktop size screens'},\n    ],\n    suggest:\n      'For extra large screens we recommend that you apply a Max-Width of 1440px for the main page content.',\n  },\n};\n\nexport const LayoutResponsiveTabs = () => {\n  const {\n    allFile: {edges: images},\n  } = useStaticQuery(graphql`\n    query {\n      allFile(filter: {relativePath: {regex: \"/^layout-guide/\"}}) {\n        edges {\n          node {\n            name\n            relativePath\n            childImageSharp {\n              gatsbyImageData\n            }\n          }\n        }\n      }\n    }\n  `);\n\n  return <TabbedContent tabsData={tabsData} images={images} isTitleTabNames />;\n};\n";